@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Magnify";
  src: url("../src/assets/fonts/magnify-extra-light.ttf")
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Magnify";
}